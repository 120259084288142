var render = function render(){var _vm=this,_c=_vm._self._c;return _c('ui-component-modal',{attrs:{"modalTitle":_vm.$t('Components.Reservation.ModalChangeDate.Main_Title'),"modalSize":"large","cssClassModalBody":"is-hidden-overflow no-padding","isSaving":_vm.isSaving,"isSavingSuccess":_vm.isSavingSuccess,"isSavingError":_vm.isSavingError,"hideFooterButtons":_vm.isSavingError || _vm.isSavingSuccess || !_vm.showInput,"disableSaveButton":!_vm.enableSaveButton,"onClickCancel":_vm.onClickCancel,"onClickSave":_vm.saveReservation,"showModal":_vm.showModal},on:{"closeModal":_vm.onClickCancel,"modalBodySize":(val) => {
      _vm.modalBodySize = val
    }},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('div',{staticClass:"slider-wrapper"},[_c('transition-group',{attrs:{"name":_vm.back ? 'slideback' : 'slide'}},[(_vm.showInput)?_c('div',{key:1,staticClass:"slideContent",style:({ height: _vm.modalBodySize.height + 'px' })},[_c('div',{staticClass:"transferDate-columns columns"},[_c('div',{staticClass:"column is-one-third"},[_c('v-date-picker',{attrs:{"mode":"single","is-inline":true,"is-required":true,"min-date":_vm.minDate,"isExpanded":true},on:{"dayclick":_vm.searchAvailability},model:{value:(_vm.startDate),callback:function ($$v) {_vm.startDate=$$v},expression:"startDate"}})],1),_c('div',{staticClass:"column-spaces column"},[(_vm.showSelectDateMessage)?_c('Message',{staticClass:"is-info"},[_vm._v(" "+_vm._s(_vm.$t('Components.Reservation.ModalChangeDate.Message_SelectDate'))+" ")]):_vm._e(),(_vm.isLoading)?_c('ui-loader'):_vm._e(),(!_vm.isLoading)?_c('div',{staticClass:"wrapper-spaces has-padding"},_vm._l((_vm.meetingspaces),function(meetingspace){return _c('div',{key:meetingspace.Id,staticClass:"has-margin-bottom-x2"},[_c('div',{staticClass:"columns is-multiline is-desktop is-vcentered"},[_c('div',{staticClass:"column is-full"},[_c('ReservationSpaceCard',{staticClass:"is-horizontal",class:{
                          'not-available': !meetingspace.IsAvailable,
                        },attrs:{"reservationSpace":meetingspace,"showSpacePrice":meetingspace.IsAvailable,"showNewPrice":false}})],1)]),(!meetingspace.IsAvailable)?_c('div',{staticClass:"notification is-warning"},[_c('ul',[(_vm.showWarning(meetingspace.CheckString, 0))?_c('li',{domProps:{"textContent":_vm._s(
                          _vm.$t(
                            'Components.Reservation.ModalChangeDate.Warning_LocationClosedOnDay'
                          )
                        )}}):_vm._e(),(_vm.showWarning(meetingspace.CheckString, 1))?_c('li',{domProps:{"textContent":_vm._s(
                          _vm.$t(
                            'Components.Reservation.ModalChangeDate.Warning_LocationClosed'
                          )
                        )}}):_vm._e(),(_vm.showWarning(meetingspace.CheckString, 2))?_c('li',[_c('div',{staticClass:"wrapper"},[_c('span',{domProps:{"textContent":_vm._s(
                              _vm.$t(
                                'Components.Reservation.ModalChangeDate.Warning_SpaceNotAvailable'
                              )
                            )}}),_c('span',[(!meetingspace.IsAvailable)?_c('a',{staticClass:"button is-small is-success",domProps:{"textContent":_vm._s(
                                _vm.$t(
                                  'Components.Reservation.ModalChangeDate.Button_ShowAlternatives'
                                )
                              )},on:{"click":function($event){_vm.setShowSpaceAlternatives(
                                  _vm.getOriginalMeetingspace(meetingspace)
                                )}}}):_vm._e()])])]):_vm._e(),(_vm.showWarning(meetingspace.CheckString, 3))?_c('li',[_c('div',{staticClass:"wrapper"},[_c('span',{domProps:{"textContent":_vm._s(
                              _vm.$t(
                                'Components.Reservation.ModalChangeDate.Warning_MinimumHoursNotMet'
                              )
                            )}}),_c('span',[(!meetingspace.IsAvailable)?_c('a',{staticClass:"button is-small is-success",domProps:{"textContent":_vm._s(
                                _vm.$t(
                                  'Components.Reservation.ModalChangeDate.Button_ShowAlternatives'
                                )
                              )},on:{"click":function($event){_vm.setShowSpaceAlternatives(
                                  _vm.getOriginalMeetingspace(meetingspace)
                                )}}}):_vm._e()])])]):_vm._e(),(_vm.showWarning(meetingspace.CheckString, 4))?_c('li',[_c('div',{staticClass:"wrapper"},[_c('span',{domProps:{"textContent":_vm._s(
                              _vm.$t(
                                'Components.Reservation.ModalChangeDate.Warning_NumberOfSeats'
                              )
                            )}}),_c('span',[(!meetingspace.IsAvailable)?_c('a',{staticClass:"button is-small is-success",domProps:{"textContent":_vm._s(
                                _vm.$t(
                                  'Components.Reservation.ModalChangeDate.Button_ShowAlternatives'
                                )
                              )},on:{"click":function($event){_vm.setShowSpaceAlternatives(
                                  _vm.getOriginalMeetingspace(meetingspace)
                                )}}}):_vm._e()])])]):_vm._e()])]):_vm._e(),_c('hr')])}),0):_vm._e()],1)])]):_vm._e(),(_vm.showSpaceAlternatives)?_c('div',{key:2,staticClass:"slideContent-alternatives slideContent",style:({ height: _vm.modalBodySize.height + 'px' })},[_c('div',{staticClass:"backbar"},[_c('a',{on:{"click":_vm.setShowInput}},[_c('span',{staticClass:"icon is-small"},[_c('font-awesome-icon',{attrs:{"icon":['fas', 'chevron-left']}})],1),_c('span',{domProps:{"textContent":_vm._s(_vm.$t('Form.Control.Back'))}})]),_c('hr')]),_c('div',{staticClass:"columns-alternatives columns is-gapless is-mobile is-vcentered"},[_c('div',{staticClass:"column is-two-fifths"},[_c('div',{staticClass:"has-padding"},[_c('ReservationSpaceCard',{attrs:{"reservationSpace":_vm.selectedSpace}})],1)]),_c('div',{staticClass:"column has-text-centered"},[_c('font-awesome-icon',{attrs:{"icon":['fas', 'arrow-right']}})],1),_c('div',{staticClass:"column-alternatives column is-two-fifths"},[_c('div',{staticClass:"has-padding no-padding-top"},[_c('div',{staticClass:"title is-3"},[_vm._v(" "+_vm._s(_vm.$t( 'Components.Reservation.ModalChangeDate.Subtitle_Alternatives' ))+" ")]),(_vm.isLoadingAlternatives)?_c('ui-loader'):_vm._l((_vm.availablity.Locations),function(location){return _c('AvailableLocation',{key:location.Id,staticClass:"list-availableSpaces",attrs:{"availableLocation":location,"meetingtypeId":_vm.reservation.MeetingtypeId,"meetingSpaces":_vm.meetingspaces,"viewType":"list-card"}})})],2)])])]):_vm._e()])],1)]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }