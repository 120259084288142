<template>
  <ui-component-modal
    :modalTitle="$t('Components.Reservation.ModalChangeDate.Main_Title')"
    modalSize="large"
    cssClassModalBody="is-hidden-overflow no-padding"
    :isSaving="isSaving"
    :isSavingSuccess="isSavingSuccess"
    :isSavingError="isSavingError"
    :hideFooterButtons="isSavingError || isSavingSuccess || !showInput"
    :disableSaveButton="!enableSaveButton"
    :onClickCancel="onClickCancel"
    :onClickSave="saveReservation"
    :showModal="showModal"
    @closeModal="onClickCancel"
    @modalBodySize="
      (val) => {
        modalBodySize = val
      }
    "
  >
    <template v-slot:content>
      <div class="slider-wrapper">
        <transition-group :name="back ? 'slideback' : 'slide'">
          <!-- Choose transfer date -->
          <div
            v-if="showInput"
            :key="1"
            class="slideContent"
            :style="{ height: modalBodySize.height + 'px' }"
          >
            <div class="transferDate-columns columns">
              <div class="column is-one-third">
                <v-date-picker
                  mode="single"
                  v-model="startDate"
                  :is-inline="true"
                  :is-required="true"
                  :min-date="minDate"
                  :isExpanded="true"
                  @dayclick="searchAvailability"
                />
              </div>
              <div class="column-spaces column">
                <Message
                  v-if="showSelectDateMessage"
                  class="is-info"
                >
                  {{ $t('Components.Reservation.ModalChangeDate.Message_SelectDate') }}
                </Message>
                <ui-loader v-if="isLoading" />
                <div
                  v-if="!isLoading"
                  class="wrapper-spaces has-padding"
                >
                  <div
                    class="has-margin-bottom-x2"
                    v-for="(meetingspace) in meetingspaces"
                    :key="meetingspace.Id"
                  >
                    <div class="columns is-multiline is-desktop is-vcentered">
                      <div class="column is-full">
                        <ReservationSpaceCard
                          :reservationSpace="meetingspace"
                          :showSpacePrice="meetingspace.IsAvailable"
                          :showNewPrice="false"
                          class="is-horizontal"
                          :class="{
                            'not-available': !meetingspace.IsAvailable,
                          }"
                        />
                      </div>
                    </div>

                    <div
                      v-if="!meetingspace.IsAvailable"
                      class="notification is-warning"
                    >
                      <ul>
                        <li
                          v-if="showWarning(meetingspace.CheckString, 0)"
                          v-text="
                            $t(
                              'Components.Reservation.ModalChangeDate.Warning_LocationClosedOnDay'
                            )
                          "
                        ></li>
                        <li
                          v-if="showWarning(meetingspace.CheckString, 1)"
                          v-text="
                            $t(
                              'Components.Reservation.ModalChangeDate.Warning_LocationClosed'
                            )
                          "
                        ></li>
                        <li v-if="showWarning(meetingspace.CheckString, 2)">
                          <div class="wrapper">
                            <span v-text="
                                $t(
                                  'Components.Reservation.ModalChangeDate.Warning_SpaceNotAvailable'
                                )
                              "></span>
                            <span>
                              <a
                                v-if="!meetingspace.IsAvailable"
                                @click="
                                  setShowSpaceAlternatives(
                                    getOriginalMeetingspace(meetingspace)
                                  )
                                "
                                class="button is-small is-success"
                                v-text="
                                  $t(
                                    'Components.Reservation.ModalChangeDate.Button_ShowAlternatives'
                                  )
                                "
                              ></a>
                            </span>
                          </div>
                        </li>
                        <li v-if="showWarning(meetingspace.CheckString, 3)">
                          <div class="wrapper">
                            <span v-text="
                                $t(
                                  'Components.Reservation.ModalChangeDate.Warning_MinimumHoursNotMet'
                                )
                              "></span>
                            <span>
                              <a
                                v-if="!meetingspace.IsAvailable"
                                @click="
                                  setShowSpaceAlternatives(
                                    getOriginalMeetingspace(meetingspace)
                                  )
                                "
                                class="button is-small is-success"
                                v-text="
                                  $t(
                                    'Components.Reservation.ModalChangeDate.Button_ShowAlternatives'
                                  )
                                "
                              ></a>
                            </span>
                          </div>
                        </li>
                        <li v-if="showWarning(meetingspace.CheckString, 4)">
                          <div class="wrapper">
                            <span v-text="
                                $t(
                                  'Components.Reservation.ModalChangeDate.Warning_NumberOfSeats'
                                )
                              "></span>
                            <span>
                              <a
                                v-if="!meetingspace.IsAvailable"
                                @click="
                                  setShowSpaceAlternatives(
                                    getOriginalMeetingspace(meetingspace)
                                  )
                                "
                                class="button is-small is-success"
                                v-text="
                                  $t(
                                    'Components.Reservation.ModalChangeDate.Button_ShowAlternatives'
                                  )
                                "
                              ></a>
                            </span>
                          </div>
                        </li>
                      </ul>
                    </div>
                    <hr />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- Show alternatives -->
          <div
            v-if="showSpaceAlternatives"
            :key="2"
            class="slideContent-alternatives slideContent"
            :style="{ height: modalBodySize.height + 'px' }"
          >
            <div class="backbar">
              <a @click="setShowInput">
                <span class="icon is-small">
                  <font-awesome-icon :icon="['fas', 'chevron-left']" />
                </span>
                <span v-text="$t('Form.Control.Back')"></span>
              </a>
              <hr />
            </div>

            <div class="columns-alternatives columns is-gapless is-mobile is-vcentered">
              <div class="column is-two-fifths">
                <div class="has-padding">
                  <ReservationSpaceCard :reservationSpace="selectedSpace" />
                </div>
              </div>
              <div class="column has-text-centered">
                <font-awesome-icon :icon="['fas', 'arrow-right']" />
              </div>
              <div class="column-alternatives column is-two-fifths">
                <div class="has-padding no-padding-top">
                  <div class="title is-3">
                    {{
                      $t(
                        'Components.Reservation.ModalChangeDate.Subtitle_Alternatives'
                      )
                    }}
                  </div>
                  <ui-loader v-if="isLoadingAlternatives" />

                  <AvailableLocation
                    v-else
                    v-for="location in availablity.Locations"
                    :key="location.Id"
                    :availableLocation="location"
                    :meetingtypeId="reservation.MeetingtypeId"
                    :meetingSpaces="meetingspaces"
                    viewType="list-card"
                    class="list-availableSpaces"
                  />
                </div>
              </div>
            </div>
          </div>
        </transition-group>
      </div>
    </template>
  </ui-component-modal>
</template>

<script>
import Vue from 'vue'
import { mapState, mapMutations } from 'vuex'
import { EventBus } from '@/eventBus/event-bus'
import availabilityProvider from '../../providers/availability'
import reservationProvider from '../../providers/reservation'

import AvailableLocation from './Availability/AvailableLocation'
import ReservationSpaceCard from './ReservationSpaceCard'
import Message from '../UI/Message.vue'

import VCalendar from 'v-calendar'
Vue.use(VCalendar, {})

export default {
  name: 'modal-change-date',

  components: {
    AvailableLocation,
    ReservationSpaceCard,
    Message,
  },

  props: {
    showModal: {
      type: Boolean,
      default: false,
    },

    onClickCancel: {
      type: Function,
      required: true,
    },
  },

  data() {
    return {
      back: false,
      showSelectDateMessage: true,
      dataFormat: 'd MMMM YYYY',
      endDate: null,
      isInitiated: false,
      isLoading: false,
      isSaving: false,
      isSavingSuccess: false,
      isSavingError: false,
      isLoadingAlternatives: false,
      meetingspaces: [],
      minDate: new Date(),
      startDate: new Date(),
      selectedSpace: null,
      showSpaceAlternatives: false,
      showInput: true,
      slideContentContainerHeight: '',
      modalBodySize: {
        width: 0,
        height: 0,
      },
      startDateISO: '',
      endDateISO: '',
      reservationDateIso: ''
    }
  },

  computed: {
    ...mapState('reservationStore', ['reservation']),

    /**
     * Enable save button when all spaces are available
     */
    enableSaveButton() {
      let allAvailable = false
      let checkCounter = 0

      if (this.meetingspaces && this.meetingspaces.length > 0) {
        for (let i = 0; i < this.meetingspaces.length; i++) {
          let ms = this.meetingspaces[i]

          if (ms.IsAvailable) {
            checkCounter = checkCounter + 1
          }
        }
      }

      if (checkCounter > 0 && checkCounter === this.meetingspaces.length) {
        allAvailable = true
      }

      return allAvailable
    },
  },

  created() {
    this.startDate = this.$options.filters.parseIsoDateStringToDate(this.reservation.StartDate)
    this.endDate = this.$options.filters.parseIsoDateStringToDate(this.reservation.EndDate)

    this.startDateISO = this.$options.filters.dateObjectIsoDateString(this.reservation.StartDate)
    this.reservationDateIso = this.startDateISO

    this.endDateISO = this.$options.filters.dateObjectIsoDateString(this.reservation.EndDate)
    if (this.reservation.MeetingtypeId !== 3) {
      this.endDate = this.reservation.StartDate
      this.endDateISO = this.$options.filters.dateObjectIsoDateString(this.reservation.StartDate)
    }

    EventBus.$on('spaceSelected', (space) => {
      this.updateSpaceWithAlternative(space)
    })
  },

  beforeDestroy() {
    EventBus.$off('spaceSelected')
  },

  mounted() {
    this.isInitiated = true
  },

  methods: {
    ...mapMutations('reservationStore', ['setReservation']),

    /**
     * Get available spaces after selecting a date
     */
    searchAvailability(val) {
      if (val.isDisabled !== false) { return }
      this.showSelectDateMessage = false
      this.startDate = val.date
      this.startDateISO = this.$options.filters.dateObjectIsoDateString(val.date)
      if (this.reservation.MeetingtypeId !== 3) {
        this.endDate = val.date
        this.endDateISO = this.$options.filters.dateObjectIsoDateString(val.date)
      }
      if (this.reservationDateIso === this.startDateISO) {
        this.showSelectDateMessage = true
        this.meetingspaces = []
        return
      }
      this.checkAvailability()
    },

    /**
     * Check available spaces
     */
    checkAvailability() {
      let self = this
      this.meetingspaces = []
      this.showSpaceAlternatives = false

      this.optionOptions = 0
      this.optionSet = 0
      this.optionVoucher = 0

      if (this.reservation.MeetingtypeId < 3) {
        this.endDate = this.startDate
      }

      this.isLoading = true

      availabilityProvider.methods
        .copyReservationCheckAvailability(
          this.reservation.Id,
          this.startDateISO,
          this.endDateISO
        )
        .then((response) => {
          self.meetingspaces = response.data
          this.isLoading = false
        })

      let t = setTimeout(() => {
        if (self.isLoading) {
          self.isLoading = false
        }
        clearTimeout(t)
      }, 2000)
    },


    showWarning(checkString, index) {
      let checks = checkString.split('#')

      return Number(checks[index]) === 0
    },

    getOriginalMeetingspace(meetingspace) {
      let orgMeetingspace = this.reservation.Spaces.find(
        (s) => s.Id === Number(meetingspace.Id)
      )

      orgMeetingspace.LocationId = this.reservation.LocationId
      return orgMeetingspace
    },


    /**
     * Update space with alternative
     */
    updateSpaceWithAlternative(space) {
      let self = this

      let spaceIndex = self.meetingspaces.findIndex(
        (m) => Number(m.Id) === this.selectedSpace.Id
      )

      if (spaceIndex > -1) {
        let newSpace = self.meetingspaces[spaceIndex]

        newSpace.SpaceId = space.SpaceId
        newSpace.SpaceName = space.SpaceName
        newSpace.SpaceImage = space.SpaceImage
        newSpace.StartDate = space.StartDate
        newSpace.StartMinutes = space.StartMinutes
        newSpace.EndDate = space.EndDate
        newSpace.EndMinutes = space.EndMinutes
        newSpace.SettingId = space.SettingId
        newSpace.PricePerSeat = space.PricePerSeat
        newSpace.PriceTotal = space.PriceTotal
        newSpace.NewPrice = space.PricePerSeat
        newSpace.NewPricePerSeat = space.PricePerSeat
        newSpace.NewPriceTotal = space.PriceTotal
        newSpace.Hash = space.Hash
        newSpace.Crc = space.Crc
        newSpace.CheckString = '1#1#1#1#1'
        newSpace.IsAvailable = true

        Vue.set(self.meetingspaces, spaceIndex, newSpace)

        self.setShowInput()
      }
    },

    saveReservation() {
      let self = this

      if (self.isSaving) { return }
      self.isSaving = true

      reservationProvider.methods
        .changeReservationDate(self.reservation.Id, self.meetingspaces)
        .then((response) => {
          if (response.status === 200) {
            // Update reservation store
            self.setReservation(response.data)

            // Send message to display toast on screen
            EventBus.$emit('showToast', {
              type: 'info',
              message: 'Reservation updated!',
            })

            this.onClickCancel()
          }
        })
        .catch((error) => {
          self.isSavingError = true
          self.responseMessage = error.response.data.Value
        })
        .finally(() => {
          self.isSaving = false
        })
    },

    setShowSpaceAlternatives(space) {
      let self = this

      if (!self.isLoadingAlternatives) {
        self.availablity = {}
        self.isLoadingAlternatives = true

        self.back = false

        self.showInput = false
        self.selectedSpace = space
        self.selectedSpace.LocationId = this.reservation.LocationId
        self.showSpaceAlternatives = true

        let countryId = 0
        let channelId = self.reservation.ChannelId
        let searchterm = ''
        let settingId = 0

        availabilityProvider.methods
          .getAvailability(
            this.startDateISO,
            this.endDateISO,
            channelId,
            countryId,
            space.LocationId,
            searchterm,
            this.reservation.MeetingtypeId,
            space.Seats,
            space.StartMinutes,
            space.EndMinutes,
            settingId
          )
          .then((response) => {
            self.availablity = response.data
          })
          .finally(() => {
            self.isLoadingAlternatives = false
          })
      }
    },

    setShowInput() {
      this.back = true
      this.showInput = true
      this.showSpaceAlternatives = false
      this.availablity = {}
      this.selectedSpace = null
    },
  },
}
</script>

<style lang="scss" scoped>
.slider-wrapper {
  overflow: hidden;
  height: 80vh;
  max-height: 600px;
}

.slideContent {
  height: 100%;
  overflow: hidden;
  .transferDate-columns {
    height: 100%;

    .column {
      .not-available {
        opacity: 0.5;
      }
    }

    .wrapper-spaces {
      overflow: auto;
      overflow-x: hidden;
      height: 100%;

      .notification {
        padding: 10px;
        .wrapper {
          display: flex;
          align-items: center;
          span {
            &:first-child {
              flex-grow: 1;
              flex-shrink: 1;
            }
          }
        }
      }
    }
  }

  &.slideContent-alternatives {
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: hidden;
    .backbar {
      flex-grow: 0;
      flex-shrink: 0;
    }
    .columns-alternatives {
      height: 87%;
      overflow: hidden;

      .column-alternatives {
        overflow: auto;
        height: 100%;
      }
    }
  }
}
</style>
