<template>
  <div
    v-if="reservationSpace"
    class="card"
  >
    <div class="card-image">
      <figure class="image is-4by3 has-background-light">
        <img
          v-if="reservationSpace.SpaceImage"
          src="@/assets/img/spacer5_3.gif"
          :style="{backgroundImage: 'url(' + $options.filters.getLocationImage(reservationSpace.SpaceImage, reservationSpace.LocationId, 640) + ')' }"
        />
      </figure>
    </div>
    <div class="card-content">
      <div
        class="title is-4 has-margin-bottom"
        v-text="reservationSpace.SpaceName"
      ></div>

      <div
        class="has-text-weight-bold has-margin-bottom"
        :key="reservationSpace.PricePerSeat"
        v-if="showSpacePrice"
      >
        <span>{{$t('Components.Reservation.ModalChangeDate.Text_PricePerSeat')}} {{ reservationSpace.PricePerSeat | toCurrency(reservationSpace.CurrencyIso) }}<br />
          {{$t('Components.Reservation.ModalChangeDate.Text_PriceTotal')}} {{ reservationSpace.PriceTotal | toCurrency(reservationSpace.CurrencyIso) }}
        </span>
      </div>

      <div>{{ reservationSpace.Seats }} {{$t('Components.Reservation.ModalChangeDate.Text_Seats')}}</div>
      <div>
        {{
          reservationSpace.StartDate
            | dateFormat(this.$i18n.locale, 'longDateFormat')
        }}
      </div>
      <div>
        <span>{{ reservationSpace.StartMinutes | minutesToTime }} -
          {{ reservationSpace.EndMinutes | minutesToTime }}</span>
      </div>
    </div>
  </div>
</template>


<script>

export default {
  props: {
    reservationSpace: {
      type: Object,
      default: null,
    },

    showSpacePrice: {
      type: Boolean,
      default: true,
    },

    showNewPrice: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      initalPricePerSeat: null
    }
  },
}
</script>

<style lang="scss" scoped>
.card {
  &.is-horizontal {
    display: flex;
    flex-direction: row;

    .card-image {
      .image {
        width: 240px;
        height: 100%;
      }
    }
  }
}
</style>